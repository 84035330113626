@mixin card($variant, $type) {
  @include WidthUp("small") {
    padding: rem(0.5) rem(1);
  }
  background-color: color-palette($variant, "card", "background");
  color: color-palette($variant, "card", "text");
  padding: rem(0.25) rem(0.5);
  box-shadow: 0 0 2px 0;
  border-radius: 4px;
  margin: 0 2px rem(0.5);
}
@mixin card-title($variant, $type) {
  font-size: rem(1.2);
  font-weight: $font-weight-bold;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin card-body($variant, $type) {
  @include WidthUp("small") {
    padding: rem(1);
  }
  padding: rem(1) rem(0.25);
}
@mixin card-footer($variant, $type) {
  // border-top: 2px inset;
  padding: rem(0.5) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Card-#{$theme} {
  &-primary {
    @include card("primary", "simple");
  }

  &-secondary {
    @include card("secondary", "simple");
  }
}

.CardTitle-#{$theme} {
  &-primary {
    @include card-title("primary", "simple");
  }

  &-secondary {
    @include card-title("secondary", "simple");
  }
}

.CardBody-#{$theme} {
  &-primary {
    @include card-body("primary", "simple");
  }

  &-secondary {
    @include card-body("secondary", "simple");
  }
}
.CardFooter-#{$theme} {
  &-primary {
    @include card-footer("primary", "simple");
  }

  &-secondary {
    @include card-footer("secondary", "simple");
  }
}
