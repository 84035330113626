.Nav-#{$theme} {
  @include layer-bg("primary");
  @include layer-color("primary");
  @include zIndex("nav");
  grid-area: ui-components-nav;
  display: grid;
  grid-template-areas: "history view-title suggestion";
  grid-template-columns: auto 1fr min-content;
  align-content: center;
  padding: 0 0.5 * $font-size;
}
.HistoryNav,
.NavTitle,
.SearchNav {
  &-#{$theme} {
    display: flex;
  }
}

.NavTitle-#{$theme} {
  grid-area: view-title;
}

.HistoryNav-#{$theme} {
  grid-area: history;
  display: flex;
  align-items: center;

  &-Arrow {
    @include nav-button();
    border: none;
    padding: 0;
    &Icon {
      @include materialIcon();
    }
  }
}

.SearchNav-#{$theme} {
  grid-area: suggestion;
}
