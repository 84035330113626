$theme: "ocean";

// TYPOGRAPHY
$font-default: "Dosis", sans-serif;
$font-numbers: "Audiowide", sans-serif;
$font-base: 14px;
$font-size: $font-base * 1;
$font-weight-normal: 200;
$font-weight-bold: 600;

$primary-color-pallete: (
  "main": (
    "background": #115171,
    "text": #bddaf3,
  ),
  "simple": (
    "background": #bddaf3,
    "text": #115171,
  ),
  "selected": (
    "background": #ed7bed,
    "text": #bddaf3,
  ),
  "positive": (
    "background": #7c6a80,
    "text": #bddaf3,
  ),
  "negative": (
    "background": #b7adb9,
    "text": #bddaf3,
  ),
  "error": (
    "background": #e83570,
    "text": #bddaf3,
  ),
  "warning": (
    "background": #bddaf3,
    "text": #e83570,
  ),
  "card": (
    "background": #23527a,
    "text": #eff7ff,
  ),
  "list": (
    "background": #23527a,
    "text": #eff7ff,
  ),
);

$secondary-color-pallete: (
  "main": (
    "background": #e9efef,
    "text": #225058,
  ),
  "simple": (
    "background": #225058,
    "text": #e9efef,
  ),
  "selected": (
    "background": #7bedac,
    "text": #225058,
  ),
  "positive": (
    "background": #367111,
    "text": #e9efef,
  ),
  "negative": (
    "background": #c9b9d1,
    "text": #e9efef,
  ),
  "error": (
    "background": #91143e,
    "text": #e9efef,
  ),
  "warning": (
    "background": #e9efef,
    "text": #91143e,
  ),
  "card": (
    "background": #ffffff,
    "text": #23527a,
  ),
);

$break-points: (
  "x-small": 320px,
  "small": 480px,
  "medium": 720px,
  "large": 960px,
  "xlarge": 1200px,
);
