@mixin footer-button() {
  text-decoration: none;
  border: 1px solid transparent;
  text-align: center;
  color: color-palette("primary", "main", "background");
  border-radius: 4px;
  padding: rem(0.25);
  opacity: 0.5;
}

@mixin footer-button-active() {
  // box-shadow: inset 0 0 4px 0px darken(color-palette("primary", "main", "text"), 25%);
  opacity: 1;
  border-radius: 4px;
}

.Footer-#{$theme} {
  @include layer-bg("secondary");
  @include layer-color("secondary");
  @include zIndex("footer");
  box-shadow: -2px 0 4px 2px lighten(color-palette("primary", "main", "text"), 30);
  grid-area: ui-components-footer;
  display: flex;
  align-items: center;
  justify-content: space-around;

  a,
  a:hover,
  a:active,
  a:link,
  a:focus {
    &.Footer-#{$theme} {
      &-Button {
        @include footer-button();
        &-ActiveLink {
          @include footer-button();
          @include footer-button-active();
        }
      }
    }
  }
  &-Label {
    font-size: rem(0.75);
  }
}
