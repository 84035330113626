@mixin html-base {
  font-size: $font-base;
  @media screen and (min-height: map-get($break-points,"small") or (min-width: map-get($break-points,"x-small"))) {
    font-size: $font-base * 1.125;
  }

  @media screen and (min-height: map-get($break-points,"large") or (min-width: map-get($break-points,"medium"))) {
    font-size: $font-base * 1.25;
  }

  @media screen and (min-height: map-get($break-points,"x-large") or (min-width: map-get($break-points,"large"))) {
    font-size: $font-base * 1.5;
  }
}

@mixin layout($view: "primary") {
  background-color: color-palette($view, "main", "background");
  font-family: $font-default;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "ui-components-nav"
    "ui-components-main"
    "ui-components-footer";
  grid-template-rows: rem(3) 1fr min-content;
  grid-template-columns: 1fr;
  margin: 0 auto;
}
html,
body {
  @include html-base();
}

.Layout-#{$theme} {
  &-primary {
    @include layout(primary);
  }
  &-secondary {
    @include layout(secondary);
  }
}

.Main-#{$theme} {
  grid-area: ui-components-main;
  overflow-y: scroll;
  @include zIndex("main");
  transform: translateX(100%);
  transition: all 300ms;

  &[data-slide-in="true"] {
    transform: translateX(0);
  }
}
