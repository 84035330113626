.Icon-#{$theme} {
  color: inherit;
  &[data-size="SMALL"] {
    @include materialIconSmall();
  }
  &[data-size="MEDIUM"] {
    @include materialIconMedium();
  }
  &[data-size="LARGE"] {
    @include materialIconLarge();
  }
  &[data-blank="true"] {
    visibility: hidden;
    width: 0;
  }
}
