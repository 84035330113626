@mixin WorklogsList() {
  @include view;
  &-Create-New {
    margin-bottom: rem(0.5);
  }

  &-ListItem-Name {
    padding: rem(0.25);
  }
  &-ListItem-Description {
    font-style: italic;
    font-size: rem(0.75);
  }
}

.WorklogsList-#{$theme} {
  &-primary {
    @include layer-bg("primary");
    @include layer-color("primary");
    @include WorklogsList();
  }

  &-secondary {
    @include layer-bg("secondary");
    @include layer-color("secondary");
    @include WorklogsList();
  }
}
