.MultipleInputContainer {
  &-#{$theme} {
    margin-bottom: rem(1);
    box-shadow: 0 0 2px 0 #cccccc;
    transition: all 300ms;

    &-add-new {
      padding: rem(1);
      display: flex;
      align-items: center;
    }
  }
}

.InputWrapper {
  display: flex;
  align-items: center;
  &-input {
    flex-grow: 1;
  }
  &-icon {
    flex-shrink: 1;
  }
}
