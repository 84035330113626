@mixin badge() {
  border-radius: rem(0.25);
  display: inline-block;
  padding: 0.25em 0.5em;
  margin: 2px 4px;
  text-align: center;
  font-size: rem(1);
}

.Badge-#{$theme} {
  &-primary {
    @include badge();
    color: color-palette("primary", "main", "background");
    background-color: color-palette("primary", "main", "text");
  }
  &-secondary {
    @include badge();
    color: color-palette("secondary", "main", "background");
    background-color: color-palette("secondary", "main", "text");
  }
}
