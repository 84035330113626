$base-size: $font-size;

.Button-#{$theme} {
  border: transparent $base-size * 1.25 ridge;
  width: 10 * $base-size;
  height: 10 * $base-size;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: darken(color-palette("primary", "main", "background"), 50%) 0 0 rem(1) 2px,
    inset lighten(color-palette("primary", "main", "background"), 10%) 0 0 rem(1) 2px;
  font-size: 1.25 * $base-size;
  transition: all 300ms;
  margin: 1 * $base-size;
}

.TimerAnimation-#{$theme} {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  opacity: 0.5;

  &:before {
    @include materialIcon(8);

    display: block;
    position: absolute;
    content: "settings";
    transform: translate(-30%, 25%);
    position: absolute;
  }
  &:after {
    @include materialIcon(6);
    display: block;
    position: absolute;
    content: "settings";
    position: absolute;
    transform: translate(73%, 22%);
  }

  &[data-turning="yes"] {
    opacity: 0.75;
    &:before {
      animation: activeGearBig 1s linear infinite;
    }
    &:after {
      animation: activeGearSmall 2s linear infinite;
    }
  }
}

.active-#{$theme} {
  box-shadow: lighten(color-palette("primary", "main", "background"), 10%) 0 0 0.25em 4px,
    inset darken(color-palette("primary", "main", "background"), 50%) 0 0 0.25em 4px;
}

.CountDown-#{$theme} {
  display: block;
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  text-align: center;
  opacity: 0;
  transform-origin: 50% 200%;
  font-size: 6em;
  font-weight: $font-weight-bold;
  animation: ct 1s linear infinite;
}

.PressAndHoldInfo-#{$theme} {
  position: absolute;
  top: 2 * $base-size;
  transform: translateY(100%);
  left: 0;
  font-size: 1 * $base-size;
  font-weight: 600;
  line-height: 1;
  flex-basis: 100%;
  width: 100%;
  text-align: center;
}
@keyframes ct {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  90% {
    opacity: 0;
    transform: scale(1.5);
  }
}

@keyframes activeGearBig {
  0% {
    transform: translate(-30%, 25%) rotate(0deg);
  }
  100% {
    transform: translate(-30%, 25%) rotate(180deg);
  }
}

@keyframes activeGearSmall {
  0% {
    transform: translate(73%, 22%) rotate(0deg);
  }
  100% {
    transform: translate(73%, 22%) rotate(-360deg);
  }
}
