$font-size: 14px;

html,
body {
  font-size: 14px;
}

html,
body,
#root {
  margin: 0;
  width: 100%;
  height: 100%;
}

body * {
  user-select: none;
  -webkit-user-select: none;
  box-sizing: border-box;
}

input {
  user-select: text;
  -webkit-user-select: text;
}

p {
  font-size: 1 * $font-size;
  padding: 1 * $font-size 0;
  line-height: 1.25 * $font-size;
}

p {
  margin: 0;
  line-height: 1.5 * $font-size;
}

a,
a:hover,
a:link,
a:visited {
  text-decoration: none;
  color: inherit;
}

input[type="date"],
input[type="time"],
input[type="text"],
input[type="phone"],
input[type="mail"],
input[type="zip"],
input[type="number"] {
  border: none;
  line-height: 1;
  height: 3 * $font-size;
  width: 100%;
  display: inline-block;
  color: inherit;
  transition: background-color 300ms;
  outline: none;
  appearance: none;

  &:focus,
  &:active {
    outline: none;
  }
}

label {
  position: relative;
}

a,
button {
  &:focus,
  &:active {
    outline: none;
  }
}
