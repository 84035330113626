$theme: "default";

// TYPOGRAPHY
$font-default: Exo, sans-serif;
$font-numbers: "Allerta Stencil", sans-serif;
$font-base: 14px;
$font-size: $font-base * 1;
$font-weight-normal: 300;
$font-weight-bold: 600;

// COLOR PALETTE
$primary-color-pallete: (
  "main": (
    "background": #3d4346,
    "text": #dbdcdd,
  ),
  "simple": (
    "background": #dbdcdd,
    "text": #3d4346,
  ),
  "selected": (
    "background": #ed7bed,
    "text": #dbdcdd,
  ),
  "positive": (
    "background": #215d41,
    "text": #dbdcdd,
  ),
  "negative": (
    "background": #b7adb9,
    "text": #dbdcdd,
  ),
  "error": (
    "background": #e83570,
    "text": #dbdcdd,
  ),
  "warning": (
    "background": #dbdcdd,
    "text": #e83570,
  ),
  "card": (
    "background": #24221e,
    "text": #e4d9c4,
  ),
  "list": (
    "background": #ecf4f3,
    "text": #321307,
  ),
);

$secondary-color-pallete: (
  "main": (
    "background": #efebe9,
    "text": #38302c,
  ),
  "simple": (
    "background": #38302c,
    "text": #efebe9,
  ),
  "selected": (
    "background": #7bedac,
    "text": #38302c,
  ),
  "positive": (
    "background": #87ad2c,
    "text": #38302c,
  ),
  "negative": (
    "background": #c3c3b3,
    "text": #38302c,
  ),
  "error": (
    "background": #91143e,
    "text": #efebe9,
  ),
  "warning": (
    "background": #efebe9,
    "text": #91143e,
  ),
  "card": (
    "background": #f9f8f6,
    "text": #24221e,
  ),
);

$break-points: (
  "x-small": 320px,
  "small": 480px,
  "medium": 720px,
  "large": 960px,
  "xlarge": 1200px,
);
