@mixin list-item($variant) {
  @include WidthUp("small") {
    padding: 0 rem(1);
  }
  padding: 0 rem(0.5);
  box-sizing: border-box;
  background-color: rgba(color-palette($variant, "card", "background"), 0.75);
  margin-bottom: 0;
  border-bottom: rgba(color-palette($variant, "card", "text"), 0.2) 1px solid;
}

.List-#{$theme} {
  // height: 100%;
  &-Item {
    &-primary {
      @include list-item("primary");
    }
    &-secondary {
      @include list-item("secondary");
    }
  }
}
