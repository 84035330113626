.Loading-#{$theme} {
  @include view();
  background-color: color-palette("primary", "main", "background");
  color: color-palette("primary", "main", "text");
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  > * {
    color: color-palette("primary", "main", "text");
  }
}

.Circle-#{$theme} {
  width: 4em;
  height: 4em;
  border: none;
  border-radius: 50%;
  box-shadow: inset 0 0 12px 2px color-palette("primary", "main", "text"),
    0 0 12px 2px color-palette("primary", "main", "text");
  position: relative;

  &:before {
    content: "";
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    background-color: color-palette("primary", "main", "background");
    width: 100%;
    height: 100%;
    transform-origin: 0% 0%;
    opacity: 0.5;
    transform: rotate(0deg);
    animation: loading 1.5s infinite linear;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
