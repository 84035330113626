.Content {
  &-#{$theme} {
    margin-bottom: rem(1);
  }
}

.Footer {
  &-#{$theme} {
    display: flex;
    justify-content: flex-end;
    margin-bottom: rem(1);
  }
}
