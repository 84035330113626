@mixin dialog($bgColor, $color) {
  position: absolute;
  display: grid;
  grid-template-areas:
    "icon caption close"
    "text text text"
    "footer footer footer";
  grid-template-columns: auto 1fr min-content;
  grid-template-rows: max-content max-content;
  padding: rem(0.5) 1 * $font-size;
  width: 90vw;
  max-width: 400px;
  background-color: lighten($bgColor, 10);
  color: lighten($color, 10);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 4px 1px;
  border-radius: 3px;
}

.Message-#{$theme} {
  @include zIndex("message");
  font-family: $font-default;
  font-size: 1 * $font-size;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.Dialog-#{$theme} {
  &__INFO,
  &__WARNING,
  &__ERROR,
  &__CONFIRM {
    &--primary {
      @include dialog(color-palette("primary", "simple", "background"), color-palette("primary", "simple", "text"));
    }
    &--secondary {
      @include dialog(color-palette("secondary", "simple", "background"), color-palette("secondary", "simple", "text"));
    }
  }
}

.Caption-#{$theme} {
  grid-area: caption;
  font-weight: 700;
  padding: 0.5 * $font-size;
  text-transform: capitalize;
}

.Icon-#{$theme} {
  grid-area: icon;
  display: flex;
  align-items: center;
}

.Close-#{$theme} {
  grid-area: close;
  width: auto;
  font-size: 1 * $font-size;
  line-height: 1;
  display: flex;
  align-items: center;
}

.Text-#{$theme} {
  grid-area: text;
  line-height: 1.5 * $font-size;
  overflow-y: scroll;
  max-height: 50vh;
  padding-top: 0.5 * $font-size;
}

.Footer-#{$theme} {
  grid-area: footer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
