@import "../form-mixins";
$bg-active: rgba(125, 125, 125, 0.125);

.Input-#{$theme} {
  &-primary {
    @include input-wrapper("primary");
  }

  &-secondary {
    @include input-wrapper("secondary");
  }

  &-label {
    @include label();
  }

  &-Wrapper {
    display: flex;
    padding-right: rem(1);
    &[data-has-focus="true"] {
      background-color: $bg-active;
    }
  }

  &-input {
    @include form-input();
  }

  &-btn-clear {
    align-self: center;
    opacity: 0;
    &[data-has-focus="true"] {
      opacity: 1;
    }
  }
}
