@mixin content($bgColor, $color) {
  position: relative;
  display: grid;
  grid-template-areas:
    "caption close"
    "text text";
  grid-template-columns: 1fr min-content;
  grid-template-rows: max-content auto;
  padding: rem(0.5) 1 * $font-size;
  width: 90%;
  height: calc(100% - #{rem(3)});
  margin: rem(4) auto;
  background-color: lighten($bgColor, 10);
  color: lighten($color, 10);
  left: 0;
  top: 100%;
  box-shadow: 0 0 4px 1px;
  border-radius: 3px 3px 0 0;
  animation: subPageSlideIn 300ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-delay: 100ms;

  &[data-hide-subpage="true"] {
    animation: subPageSlideOut 300ms;
  }
}

.SubPageView-#{$theme} {
  @include zIndex("subpage");
  font-family: $font-default;
  font-size: 1 * $font-size;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.Backdrop-#{$theme} {
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 300ms;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  &:active {
    background-color: rgba(30, 0, 0, 0.25);
  }
}

.Content-#{$theme} {
  &--primary {
    @include content(color-palette("primary", "simple", "background"), color-palette("primary", "simple", "text"));
  }
  &--secondary {
    @include content(color-palette("secondary", "main", "background"), color-palette("secondary", "main", "text"));
  }
}

.Caption-#{$theme} {
  grid-area: caption;
  font-weight: 700;
  padding: 0.5 * $font-size;
}

.Icon-#{$theme} {
  grid-area: icon;
  display: flex;
  align-items: center;
}

.Close-#{$theme} {
  grid-area: close;
  width: auto;
  font-size: 1 * $font-size;
  line-height: 1;
  display: flex;
  align-items: center;
}

.Text-#{$theme} {
  grid-area: text;
  line-height: 1.5 * $font-size;
  overflow-y: scroll;
  padding-top: 0.5 * $font-size;
  max-height: 100%;
}

@keyframes subPageSlideIn {
  from {
    top: 100%;
  }
  to {
    top: 0;
  }
}

@keyframes subPageSlideOut {
  from {
    top: 0;
  }
  to {
    top: 100%;
  }
}
