@mixin contact-details() {
  @include view;
  &-Footer {
    display: flex;
    justify-content: space-evenly;
  }
}

@mixin read-only($variant: "primary") {
}

.ContactDetails-#{$theme} {
  &-primary {
    @include layer-bg("primary");
    @include layer-color("primary");
    @include contact-details();
  }

  &-secondary {
    @include layer-bg("secondary");
    @include layer-color("secondary");
    @include contact-details();
  }
}

.ReadOnly {
  &-#{$theme} {
    &-primary {
      @include read-only("primary");
    }
    &-secondary {
      @include read-only("secondary");
    }
  }
}
