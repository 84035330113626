@mixin options() {
  @include view();
}

@mixin option-name {
  padding: 0.5 * $font-size;
}

.Options-#{$theme} {
  &-primary {
    @include layer-bg("primary");
    @include layer-color("primary");
    @include options();
  }

  &-secondary {
    @include layer-bg("secondary");
    @include layer-color("secondary");
    @include options();
  }
}

.OptionListName-#{$theme} {
  @include option-name();
  font-weight: $font-weight-bold;
}
