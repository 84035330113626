@mixin timer {
  @include view;
  @include HeightUp("small") {
    flex-wrap: wrap;
  }
  width: 100%;
  height: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
  grid-area: ui-components-timer;
  overflow: hidden;
}

.Timer-#{$theme} {
  &-primary {
    @include layer-bg("primary");
    @include layer-color("primary");
    @include timer();
  }

  &-secondary {
    @include layer-bg("secondary");
    @include layer-color("secondary");
    @include timer();
  }
}

.Counter-#{$theme} {
  font-size: 5 * $font-size;
  font-family: $font-numbers;
  opacity: 0.5;
  display: flex;
  transition: opacity 300ms;
  width: 100%;
  justify-content: space-around;
  transition: all 300ms;
  text-shadow: 2px 2px 10px color-palette("primary", "main", "background"),
    -2px -2px 10px color-palette("primary", "main", "background");
  @include WidthUp("small") {
    @include HeightUp("x-small") {
      font-size: 8 * $font-size;
    }
  }
}

.Hour-#{$theme} {
  display: inline-block;
  text-align: right;
  flex-basis: calc(50% - 1rem);
}

.Minute-#{$theme} {
  display: inline-block;
  text-align: left;
  flex-basis: calc(50% - 1rem);
}

.Second-#{$theme} {
  font-size: 1 * $font-size;
  position: absolute;
}

.Beats-#{$theme} {
  display: inline-block;
  text-align: center;
  line-height: 1;
}

.beat-#{$theme} {
  animation: bt 1s linear infinite;
}
@keyframes bt {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
