@function rem($size) {
  @return $size * $font-size;
}

@mixin layer-bg($type) {
  background-color: color-palette($type, "main", "background");
}

@mixin layer-color($type) {
  color: color-palette($type, "main", "text");
}

@mixin WidthUp($break) {
  @media screen and (min-width: map-get($break-points,$break)) {
    @content;
  }
}

@mixin HeightUp($break) {
  @media screen and (min-height: map-get($break-points,$break)) {
    @content;
  }
}

@mixin view {
  padding: rem(1) rem(0.5);
  min-height: 100%;
  overflow: auto;
  @include WidthUp("small") {
    padding: rem(2) rem(1);
  }
}

@mixin zIndex($item) {
  $indexList: (
    "main": 100,
    "nav": 1000,
    "footer": 1000,
    "subpage": 1500,
    "message": 2000,
  );
  z-index: map-get($indexList, $item);
}

@mixin materialIcon($size: 1.75) {
  width: rem($size);
  height: rem($size);
  overflow: hidden;
  flex-shrink: 0;
  user-select: none;
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: rem($size);
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

@mixin materialIconSmall() {
  @include materialIcon(2);
}
@mixin materialIconMedium() {
  @include materialIcon(2.5);
}
@mixin materialIconLarge() {
  @include materialIcon(3);
}

@function color-palette($variantName: "primary", $param: "main", $type: "background") {
  $variant: (
    "primary": $primary-color-pallete,
    "secondary": $secondary-color-pallete,
  );
  $selectedVariant: map-get($variant, $variantName);
  $selectedVariantType: map-get($selectedVariant, $param);

  @return map-get($selectedVariantType, $type);
}

@mixin nav-button() {
  display: flex;
  align-items: center;
  padding: 0 1 * $font-size;
  border: 1px solid;
  border-radius: 0.25 * $font-size;
  margin: 0 0.25 * $font-size;
  cursor: pointer;

  &[data-disabled="true"] {
    cursor: auto;
    opacity: 0.25;
  }
  &[hidden] {
    display: none;
  }

  &-Text {
    display: none;
    @media screen and (min-width: map-get($break-points,"small")) {
      display: initial;
    }
  }
}
