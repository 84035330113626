@import "../form-mixins";

.RadioGroup {
  &-#{$theme} {
    display: flex;
    flex-direction: column;
  }
}

.Radio {
  &-#{$theme} {
    @include option-container("radio");
  }
}
