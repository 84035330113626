@mixin basicButton($variant: "primary", $action-class: "simple") {
  $BG: color-palette($variant, "simple", "background");
  $C: color-palette($variant, "simple", "text");

  @if ($action-class== "negative") {
    $BG: color-palette($variant, "negative", "background");
    $C: color-palette($variant, "negative", "text");
  }
  @if ($action-class== "positive") {
    $BG: color-palette($variant, "positive", "background");
    $C: color-palette($variant, "positive", "text");
  }
  @if ($action-class== "error") {
    $BG: color-palette($variant, "error", "background");
    $C: color-palette($variant, "error", "text");
  }
  @if ($action-class== "warning") {
    $BG: color-palette($variant, "warning", "background");
    $C: color-palette($variant, "warning", "text");
  }

  font-family: inherit;
  background-color: $BG;
  color: $C;
  padding: rem(0.25) rem(1);
  font-size: rem(1);
  margin: 0 rem(0.5);
  display: inline-flex;
  align-items: center;

  justify-content: space-between;
  transition: all 300ms;
  position: relative;

  &-innerText {
    margin: 0 rem(0.5);
    text-transform: capitalize;
  }

  &[data-icon-only="true"] {
    border-radius: 50%;
    padding: rem(0.5);
  }

  &[data-disabled="true"] {
    background-color: transparentize($color: $BG, $amount: 0.25);
    color: transparentize($color: $C, $amount: 0.5);
  }

  &:active:not([data-disabled="true"]) {
    transform: scale(1.25);
    background-color: $C;
    color: $BG;
  }

  a,
  button {
    appearance: none;
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    display: flex;
    align-items: center;
    color: inherit;
  }
}

.Button-Container {
  display: flex;
  &[data-align="inline"] {
    display: inline-flex;
  }
  &[data-align="left"] {
    justify-content: flex-start;
  }
  &[data-align="right"] {
    justify-content: flex-end;
  }
  &[data-align="center"] {
    justify-content: center;
  }
  &[data-align="stretch"]:not([data-icon-only="true"]) {
    flex-basis: 100%;
    & > div {
      display: flex;
      flex-grow: 1;
      justify-content: center;
    }
  }
}

.Btn-#{$theme} {
  &-primary {
    &--simple {
      @include basicButton("primary", "simple");
    }
    &--negative {
      @include basicButton("primary", "negative");
    }
    &--positive {
      @include basicButton("primary", "positive");
    }
    &--error {
      @include basicButton("primary", "error");
    }
    &--warning {
      @include basicButton("primary", "warning");
    }
  }

  &-secondary {
    &--simple {
      @include basicButton("secondary", "simple");
    }
    &--negative {
      @include basicButton("secondary", "negative");
    }
    &--positive {
      @include basicButton("secondary", "positive");
    }
    &--error {
      @include basicButton("secondary", "error");
    }
    &--warning {
      @include basicButton("primary", "warning");
    }
  }
}
