@mixin Appointments() {
  @include view;
  &-Create-New {
    margin-bottom: rem(0.5);
  }

  &-Content {
    position: relative;
  }

  &-Type {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.Appointments-#{$theme} {
  &-primary {
    @include layer-bg("primary");
    @include layer-color("primary");
    @include Appointments();
  }

  &-secondary {
    @include layer-bg("secondary");
    @include layer-color("secondary");
    @include Appointments();
  }
}
